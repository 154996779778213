









import { Component, Vue } from "vue-property-decorator";
import dataTable from "../components/DataTable.vue";
import { tabelSettings, ITableSettings,practiceTableSettings} from "./Models/TableSettings";
import { UserPractice } from "./Models/TableEditClass";

@Component({
  components: {
    dataTable,
  },
})
export default class Practices extends Vue {
  patientsTable:ITableSettings=practiceTableSettings
}
